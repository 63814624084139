import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import * as styles from './card.module.scss';

interface Props {
  cardClassNames?: string;
}

const Card = (props: PropsWithChildren<Props>) => {
  const { children, cardClassNames } = props;
  const classes = classnames(styles.card, cardClassNames);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default Card;
